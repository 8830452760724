import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MailtasticDashboardComponent } from './components/mt-dashboard/dashboard.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DashboardComponent
  },
  {
    path: 'overview',
    component: MailtasticDashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {}
