<div class="container-fluid" *transloco="let translate">
  <div class="row">
    <div
      class="col-5 img-testimonial onboardimg-left"
      [ngClass]="isCogsig ? 'cogsig' : 'mailtastic'"
    ></div>
    <div class="col-7">
      <div class="container">
        <div class="modal-body">
          <div class="header upgrade-info">
            <p
              class="marbot20 medium"
              [innerHTML]="
                translate('param.trial_over_modal_1', {
                  value: (accountData.firstName | titlecase)
                })
              "
            ></p>
            <p class="marbot20 medium">{{ translate('trial_over_modal_2') }}</p>
            <p class="marbot20 bold">{{ translate('trial_over_modal_3') }}</p>
            <p class="marbot20 light">{{ translate('trial_over_modal_4') }}</p>
          </div>
          <div class="ownbtn btn btnblau" (click)="modal.close(true)">
            {{ translate('trial_over_modal_btn_1') }}
          </div>
          <div class="text-center">
            <a (click)="modal.close(true)">
              {{ translate('trial_over_modal_btn_2') }}
            </a>
            <!-- Temporory hide -->
            <!-- <a
              transloco="trial_over_modal_btn_3"
              href="mailto:t.bauer@mailtastic.com"
            ></a> -->
            <span class="cursor-pointer" (click)="modal.close(false)">
              {{ translate('logout') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
