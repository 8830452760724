<ng-container *ngIf="loadDashboardData$ | async"></ng-container>
<div class="container-fluid">
  <ng-container *ngIf="loadData$ | async as loadData">
    <!-- Top Area -->
    <mt-header
      [overAllStatus]="overAllStats"
      [loadingPromise]="loadData.loadingPromise"
    ></mt-header>

    <div class="container-fluid martop15">
      <div class="row row-eq-height-lg margin-hor-0">
        <!-- Campaigns list -->
        <div class="col-lg-5 col-12 display-block">
          <mt-active-campaigns
            [activeCampaigns]="activeCampaigns"
            [loadingPromise]="loadData.loadingPromise"
          ></mt-active-campaigns>
        </div>

        <!-- Linegraph -->
        <div class="col-lg-7 col-12">
          <mt-line-graph
            *ngIf="activeCampaigns.length > 0; else graphEmptyState"
            class="line-graph"
            lineTitle="Statistic"
          ></mt-line-graph>

          <ng-template #graphEmptyState>
            <div
              id="linegraphPanel"
              class="panel full-width line-graph-panel marbot20"
            >
              <div class="panel-heading">
                <h5 transloco="Statistic"></h5>
              </div>
              <mt-emptystate
                [loadingPromise]="loadData.loadingPromise"
                [showEmpty]="activeCampaigns.length === 0"
                hltitle="emptystate_graph_hl"
                subtitle="emptystate_graph_sl"
                class="nodataarea"
                svg="data"
              ></mt-emptystate>
            </div>
          </ng-template>
        </div>

        <!-- Department statistics -->
        <div
          class="martop75 display-block"
          [ngClass]="
            overAllStats.hideTop3Employees
              ? 'col-lg-12 col-sm-12 col-xs-12'
              : 'col-lg-6 col-12 col-sm-6 col-xs-6'
          "
        >
          <mt-department-statistics
            [isDataAvailable]="pieChartOptions.progressImpressions.length > 0"
            [pieChartOptions]="pieChartOptions"
            [loadingPromise]="loadData.loadingPromise"
            (changePieChartMode)="changePieChartMode($event)"
          ></mt-department-statistics>
        </div>

        <!-- Top 3 employees -->
        <div class="col-lg-6 col-12 col-sm-6 col-xs-6 martop75 display-block">
          <mt-top-employees
            [isDataAvailable]="pieChartOptions.progressImpressions.length > 0"
            [hideTop3Employees]="overAllStats.hideTop3Employees"
            [best3Criteria]="best3Criteria"
            (changeBest3CriteriaSort)="changeBest3CriteriaSort($event)"
            [loadingPromise]="loadData.loadingPromise"
            class="bg-size-wrap"
          ></mt-top-employees>
        </div>
      </div>
    </div>

    <!-- Gated Content -->
    <mt-gated-content
      *ngIf="!loadData.authorizationScope.hasCampaignTracking"
    ></mt-gated-content>
  </ng-container>
</div>
