import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  /**
   * Basic details and status
   * @defaultValue
   * {
   * amountOfUsers: 0,
   * amountOfCampaigns: 0,
   * amountOfClicks: 0,
   * amountOfViews: 0,
   * hideTop3Employees: false
   * }
   */
  @Input() overAllStatus = {
    amountOfUsers: 0,
    amountOfCampaigns: 0,
    amountOfClicks: 0,
    amountOfViews: 0,
    hideTop3Employees: false
  };

  /**
   * Status of data loading
   * @defaultValue - false
   */
  @Input() loadingPromise = false;
}
