import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeStats } from 'src/app/services/employee/employee-service.interface';

@Component({
  selector: 'mt-top-employees',
  templateUrl: './top-employees.component.html',
  styleUrls: ['./top-employees.component.scss']
  // changeDetection: ChangeDetectionStrategy.Default
})
export class TopEmployeesComponent {
  /**
   * Determiones the status of pie chart data to adjust the height of right side section
   * @defaultValue - false
   */
  @Input() isDataAvailable = false;

  /**
   * Status of hide top3 employees
   * @defaultValue - false
   */
  @Input() hideTop3Employees = false;

  /**
   * Status of loading promise
   * @defaultValue - false
   */
  @Input() loadingPromise = false;

  /**
   * Basic details of criteria
   * @defaultValue
   * {
   * employees: [],
   * best3Employees: [],
   * sortBy: {
   *  views: true,
   *  clicks: false,
   *  clickRate: false
   * }
   * }
   */
  @Input() best3Criteria = {
    employees: [] as EmployeeStats[],
    best3Employees: [] as EmployeeStats[],
    sortBy: {
      // Used to determine which type of data is being sorted by
      views: true,
      clicks: false,
      clickRate: false
    }
  };

  /**
   * Triggers the sorting type of the best 3 employees.
   */
  @Output() changeBest3CriteriaSort = new EventEmitter<string>();

  constructor(public router: Router) {}

  /**
   * Triggers the sorting type of the best 3 employees.
   * @param mode - Data type to sort by
   */
  changeBest3Criteria(mode: string): void {
    this.changeBest3CriteriaSort.emit(mode);
  }
}
