<div id="campaignlistPanel" class="panel custompanel top3groupcomp">
  <div class="panel-heading button-panel-header">
    <h5
      ngbTooltip="{{ 'activeIsCampWhenAssignedToAtLeastOneGroup' | transloco }}"
      tooltipClass="status-info-tooltip"
      placement="top"
      transloco="Activecampaigns"
    ></h5>

    <mt-button
      class="roboto_light"
      color="primary"
      [routerLink]="['/campaigns/create/sender']"
      icon="plus"
      text="Createcampaign"
    >
    </mt-button>
  </div>
  <div class="panel-body campaign-list-panel-body">
    <mt-emptystate
      [loadingPromise]="loadingPromise"
      [showEmpty]="activeCampaigns.length === 0"
      class="nodataarea"
      hltitle="Youcurrentlyhavenoactivecampaign"
      subtitle="Nowcreateacampaignandassignthem"
      svg="campaign"
    ></mt-emptystate>
    <ul class="no_padding">
      <!-- trackBy: trackByCampaign TODO: Add down -->
      <li
        *ngFor="let item of activeCampaigns"
        class="campaign-list-item cursor-pointer"
      >
        <div class="col-lg-2 col-md-2 col-sm-2 hidden-xs">
          <img [src]="item.image" alt="Campaign Image" />
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-4 col-xs-9 title-area flex-center-left"
        >
          <div>
            <div
              class="upper"
              tooltipClass="status-info-tooltip"
              placement="top"
              ngbTooltip="{{ item.title | transloco }}"
            >
              <div class="title">{{ item.title | textTruncate : 20 }}</div>
            </div>
            <div class="campaigndetails">
              <span
                class="graph-dot"
                [ngStyle]="{ 'background-color': item.color }"
              ></span>
              <span
                >{{ 'From' | transloco }}
                {{ item.createdAt | date : 'dd.MM.yyyy' }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="col-lg-2 col-md-2 col-sm-2 hidden-xs statsitem no_padding"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ 'data-yesterday' | transloco }}"
        >
          <span class="upper">{{ item.views }}</span>
          <br />
          <div class="subtitle">
            <span class="down" transloco="sort_impressions"></span>
            <span
              ng-include="'img/svgs/info.svg'"
              ng-show="item._hover"
              class="mt-hellgrau mini-svg marleft3"
            ></span>
          </div>
        </div>
        <div
          class="col-lg-2 col-md-2 col-sm-2 hidden-xs statsitem no_padding"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ 'data-yesterday' | transloco }}"
        >
          <span class="upper">{{ item.clicks }}</span>
          <br />
          <div class="subtitle">
            <span class="down" transloco="clicks"></span>
            <span
              ng-include="'img/svgs/info.svg'"
              ng-show="item._hover"
              class="mt-hellgrau mini-svg marleft3"
            ></span>
          </div>
        </div>
        <div
          class="col-lg-2 col-md-2 col-sm-2 col-xs-3 statsitem no_padding"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ 'data-yesterday' | transloco }}"
        >
          <span class="upper"
            >{{
              item.views === 0 || item.clicks === 0
                ? 0
                : ((item.clicks / item.views) * 100 | number : '1.2-2')
            }}%</span
          >
          <br />
          <div class="subtitle">
            <span class="down" transloco="sort_rate"></span>
            <span
              ng-include="'img/svgs/info.svg'"
              ng-show="item._hover"
              class="mt-hellgrau mini-svg marleft3"
            ></span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
