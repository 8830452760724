<div class="top3groupcomp panel custompanel group-compare-panel">
  <div class="panel-heading">
    <h5 transloco="Statisticsdepartment"></h5>
    <div
      class="btn-group"
      role="btn-group"
      *ngIf="pieChartOptions.progressImpressions.length"
    >
      <button
        type="button"
        (click)="changePieChart('views')"
        [ngClass]="{
          darkbutton: pieChartOptions.typeOfSelectedData.views
        }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_impressions"
      ></button>
      <button
        type="button"
        (click)="changePieChart('clicks')"
        [ngClass]="{
          darkbutton: pieChartOptions.typeOfSelectedData.clicks
        }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_clicks"
      ></button>
      <button
        type="button"
        (click)="changePieChart('clickRate')"
        [ngClass]="{
          darkbutton: pieChartOptions.typeOfSelectedData.clickRate
        }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_rate"
      ></button>
    </div>
  </div>
  <div class="panel-body">
    <div class="row" *ngIf="isDataAvailable">
      <div class="col">
        <div class="chart-container">
          <ngx-charts-pie-chart
            [doughnut]="true"
            [customColors]="pieChartOptions.customColors"
            [results]="pieChartOptions.dataToShow"
            #pieChart
          >
          </ngx-charts-pie-chart>
        </div>
      </div>
      <div class="col align-self-center">
        <div class="bar_areas">
          <div *ngIf="pieChartOptions.typeOfSelectedData.views">
            <ng-container
              *ngFor="let item of pieChartOptions.progressImpressions"
            >
              <div class="progress_title">
                <span
                  class="glyphicon glyphicon-folder-open groupgraphglyph"
                  aria-hidden="true"
                ></span>
                <span class="grouptitle"> {{ item.name }}</span>

                <span
                  class="total_number"
                  *ngIf="!pieChartOptions.showRelativeBarData"
                  >{{ item.value }}</span
                >
                <span
                  class="total_number"
                  *ngIf="pieChartOptions.showRelativeBarData"
                  >{{ item.contribution }} %</span
                >
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [attr.aria-valuenow]="item.contribution"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  [ngStyle]="{
                    'width.%': item.contribution,
                    'background-color': item.color
                  }"
                ></div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="pieChartOptions.typeOfSelectedData.clicks">
            <ng-container *ngFor="let item of pieChartOptions.progressClicks">
              <div class="progress_title">
                <span
                  class="glyphicon glyphicon-folder-open groupgraphglyph"
                  aria-hidden="true"
                ></span>
                <span class="grouptitle"> {{ item.name }}</span>

                <span
                  class="total_number"
                  *ngIf="!pieChartOptions.showRelativeBarData"
                  >{{ item.value }}</span
                >
                <span
                  class="total_number"
                  *ngIf="pieChartOptions.showRelativeBarData"
                  >{{ item.contribution }} %</span
                >
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [attr.aria-valuenow]="item.contribution"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  [ngStyle]="{
                    'width.%': item.contribution,
                    'background-color': item.color
                  }"
                ></div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="pieChartOptions.typeOfSelectedData.clickRate">
            <ng-container *ngFor="let item of pieChartOptions.progressRate">
              <div class="progress_title">
                <span
                  class="glyphicon glyphicon-folder-open groupgraphglyph"
                  aria-hidden="true"
                ></span>
                <span class="grouptitle"> {{ item.name }}</span>

                <span
                  class="total_number"
                  *ngIf="!pieChartOptions.showRelativeBarData"
                  >{{ item.value }} %</span
                >
                <span
                  class="total_number"
                  *ngIf="pieChartOptions.showRelativeBarData"
                  >{{ item.contribution }} %</span
                >
              </div>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  [attr.aria-valuenow]="item.contribution"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  [ngStyle]="{
                    'width.%': item.contribution,
                    'background-color': item.color
                  }"
                ></div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="full-width">
          <div class="flex-center-all switchform">
            <span transloco="Absolute"></span>
            <ui-switch
              class="padding5"
              [(ngModel)]="pieChartOptions.showRelativeBarData"
            ></ui-switch>
            <span transloco="Relative"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Empty state -->
    <mt-emptystate
      [loadingPromise]="loadingPromise"
      [showEmpty]="pieChartOptions.progressImpressions.length === 0"
      class="nodataarea dashboardgroups"
      hltitle="Currentlyarenodata"
      subtitle="Oncefirstdataareavailable"
      svg="data"
    ></mt-emptystate>
  </div>
</div>
