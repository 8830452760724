import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BarData, CustomColor, PieChartData } from 'src/app/model/interfaces/line-graph.interface';

@Component({
  selector: 'mt-department-statistics',
  templateUrl: './department-statistics.component.html',
  styleUrls: ['./department-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepartmentStatisticsComponent {
  /**
   * Determiones the status of pie chart data
   * @defaultValue - false
   */
  @Input() isDataAvailable = false;

  /**
   * Status of data loading promise
   * @defaultValue - false
   */
  @Input() loadingPromise = false;

  /**
   * Basic details of pie chart options
   * @defaultValue
   * {
   * dataToShow: [] as IPieChartData[],
   * impressionsData: [] as IPieChartData[],
   * clicksData: [] as IPieChartData[],
   * rateData: [] as IPieChartData[],
   * totalImpressions: 0,
   * totalClicks: 0,
   * totalRate: 0,
   * progressImpressions: [] as IBarData[],
   * progressClicks: [] as IBarData[],
   * progressRate: [] as IBarData[],
   * customColors: [] as ICustomColor[],
   * typeOfSelectedData:
   * {
   *  views: true,
   *  clicks: false,
   *  clickRate: false
   * },
   * showRelativeBarData: false
   * }
   */
  @Input() pieChartOptions = {
    dataToShow: [] as PieChartData[],
    impressionsData: [] as PieChartData[],
    clicksData: [] as PieChartData[],
    rateData: [] as PieChartData[],
    totalImpressions: 0,
    totalClicks: 0,
    totalRate: 0,
    progressImpressions: [] as BarData[],
    progressClicks: [] as BarData[],
    progressRate: [] as BarData[],
    customColors: [] as CustomColor[],
    typeOfSelectedData: {
      views: true,
      clicks: false,
      clickRate: false
    },
    showRelativeBarData: false // Used to determine which type of data the progress bars show
  };

  /**
   * Triggers the pie chart to impressions view
   */
  @Output() changePieChartMode = new EventEmitter<string>();

  /**
   * Changes the displayed data type in the pie chart.
   * Impressions by default.
   * @param mode - New data type to display
   */
  changePieChart(mode: string): void {
    this.changePieChartMode.emit(mode);
  }
}
