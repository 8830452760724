<ng-container *ngIf="hasSubscription$ | async">
  <cogsig-dashboard-page></cogsig-dashboard-page>
</ng-container>

<!-- Temporary commented the code. It will be used in future after green navibar & guide tour being completed for mailtastic -->
<!-- <ng-container *ngIf="isCogSig; else mtDashboard">
  <cogsig-dashboard-page></cogsig-dashboard-page>
</ng-container>

<ng-template #mtDashboard>
  <mt-dashboard-page></mt-dashboard-page>
</ng-template> -->
