<div
  class="panel custompanel top3groupcomp"
  *ngIf="!hideTop3Employees"
  [ngClass]="{ sectionHeight: isDataAvailable }"
>
  <div class="panel-heading flex-center-space-between">
    <h5 transloco="bestemployee"></h5>
    <div
      class="btn-group"
      role="btn-group"
      *ngIf="best3Criteria.best3Employees.length; else createEmployee"
    >
      <button
        type="button"
        (click)="changeBest3Criteria('views')"
        [ngClass]="{ darkbutton: best3Criteria.sortBy.views }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_impressions"
      ></button>
      <button
        type="button"
        (click)="changeBest3Criteria('clicks')"
        [ngClass]="{ darkbutton: best3Criteria.sortBy.clicks }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_clicks"
      ></button>
      <button
        type="button"
        (click)="changeBest3Criteria('clickRate')"
        [ngClass]="{ darkbutton: best3Criteria.sortBy.clickRate }"
        class="btn btn-default smallfontgroupb"
        transloco="sort_rate"
      ></button>
    </div>
    <ng-template #createEmployee>
      <mt-button
        text="Addemployees"
        class="roboto_light"
        icon="plus"
        color="primary"
        (click)="router.navigate(['/employees/create'])"
      ></mt-button>
    </ng-template>
  </div>
  <div class="panel-body">
    <ul class="lists">
      <li
        class="listrow cursor-pointer"
        *ngFor="
          let item of best3Criteria.best3Employees;
          index as i;
          odd as isOdd;
          even as isEven
        "
        [class.odd]="isOdd"
        [class.even]="isEven"
        ng-mouseenter="item._hover = true"
        ng-mouseleave="item._hover = false"
      >
        <div class="tcell first">
          <employeecol [user]="item" [index]="i + 1"></employeecol>
        </div>
        <div class="tcell second">
          <statscol [amount]="item.views" unit="Impressions"></statscol>
        </div>
        <div class="tcell third">
          <statscol [amount]="item.clicks" unit="Clicks"></statscol>
        </div>
        <div class="tcell last">
          <statscol
            [amount]="
              item.views === 0 || item.clicks === 0
                ? 0
                : item.clicks / item.views
            "
            unit="Click Rate"
          ></statscol>
        </div>
      </li>
    </ul>

    <!-- Empty state -->
    <mt-emptystate
      [loadingPromise]="loadingPromise"
      [showEmpty]="best3Criteria.best3Employees.length === 0"
      class="nodataarea centered-axis-xy"
      hltitle="Thereemployeewhocanbeassignedtothegroup"
      subtitle="Notegrouparenotdisplayed"
      svg="employees"
    ></mt-emptystate>
  </div>
</div>
