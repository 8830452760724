import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICampaignGet } from 'src/app/services/campaign/campaign-services.interface';

@Component({
  selector: 'mt-active-campaigns',
  templateUrl: './active-campaigns.component.html',
  styleUrls: ['./active-campaigns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveCampaignsComponent {
  /**
   * Status of data loading promise
   * @defaultValue - false
   */
  @Input() loadingPromise = false;

  /**
   * Triggers the active campaigns data
   * @defaultValue - []
   */
  @Input() activeCampaigns: ICampaignGet[] = [];
}
