<div class="container-fluid dashboard-top-area">
  <div class="row row-eq-height margin-hor-0">
    <div class="col-lg-12 col-md-12">
      <div class="top-wrapper stats full-width count-section">
        <div
          class="display"
          tooltipClass="status-info-tooltip"
          tooltipPlacement="top"
          ngbTooltip="{{ 'inThisAreaYouSeeCampSummary' | transloco }}"
          transloco="Current"
        ></div>
        <div class="stats_entry">
          <h1>{{ overAllStatus.amountOfCampaigns | number : '1.0-0' }}</h1>
          <p transloco="campaigns"></p>
        </div>
        <div class="stats_entry spacer hidden-xs visible-lg-block"></div>
        <div class="stats_entry visible-lg-block">
          <h1>{{ overAllStatus.amountOfUsers | number : '1.0-0' }}</h1>
          <p transloco="Employee"></p>
        </div>
        <div class="stats_entry spacer hidden-xs"></div>
        <div class="stats_entry">
          <h1>{{ overAllStatus.amountOfViews | number : '1.0-0' }}</h1>
          <p transloco="sort_impressions"></p>
        </div>
        <div class="stats_entry spacer hidden-xs"></div>
        <div class="stats_entry">
          <h1>{{ overAllStatus.amountOfClicks | number : '1.0-0' }}</h1>
          <p transloco="sort_clicks"></p>
        </div>
        <div class="stats_entry spacer hidden-xs"></div>
        <div class="stats_entry">
          <h1>
            {{ overAllStatus.amountOfViews === 0 || overAllStatus.amountOfClicks
            === 0 ? 0 : ((overAllStatus.amountOfClicks /
            overAllStatus.amountOfViews) * 100 | number: '1.2-2') }}%
          </h1>
          <p transloco="sort_rate"></p>
        </div>
      </div>
    </div>
  </div>
</div>
